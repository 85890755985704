@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Trade+Winds&display=swap');

* {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: .2em;
}

::-webkit-scrollbar-thumb:horizontal{
  background-color: black;
  outline: 1px solid black;
}
 
::-webkit-scrollbar-thumb {
  background-color: #1AFFAE;
  outline: 1px solid #1AFFAE;
}
body {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #108db8;
  font-weight: bold;
}

img {
  max-width: 100%;
}

nav {
  width: 100%;
  background: #108db8;
}

nav a {
  color: white;
  padding: 1rem;
  display: inline-block;
}
.appbutton{
  font-weight: bold;
  font-size: 2rem;
}

.landingQuote{
  font-style: italic;
  text-transform: uppercase;
  font-size: 4.5rem;
}

.scrolldown {
  --color: white;
  --sizeX: 30px;
  --sizeY: 50px;
  position: relative;
  /* right: 49%; */
  /* bottom: 0%; */
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--color);
  border-radius: 50px;
  box-sizing: border-box;
  margin: 16px;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #ffffff66;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }
  40% {
    opacity: 1;
    height: 10px;
  }
  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }
  100% {
    height: 3px;
    opacity: 0;
  }
}

.soundwave-container {
  height: 30px;
  width: auto;
  display: flex;
  align-items:center;
  justify-content: center;
  /* margin-right: 15px; */
}
.bar {
  width: 3px;
  height: 100%;
  background: #1AFFAE;
  border-radius: 2px;
  margin: 0 2px;
  animation: equalizer 1.9s steps(20, end) infinite;
}
.bar:nth-child(1) { 
  animation-duration: 1.9s;
}
.bar:nth-child(2) { 
  animation-duration: 2.0s;
}
.bar:nth-child(3) { 
  animation-duration: 1.7s;
}
.bar:nth-child(4) { 
  animation-duration: 2.1s;
}


@keyframes equalizer {
  0% {
    height: 60%;
  }
  4% {
    height: 50%;
  }
  8% {
    height: 40%;
  }
  12% {
    height: 30%;
  }
  16% {
    height: 20%;
  }
  20% {
    height: 30%;
  }
  24% {
    height: 40%;
  }
  28% {
    height: 10%;
  }
  32% {
    height: 40%;
  }
  36% {
    height: 60%;
  }
  40% {
    height: 20%;
  }
  44% {
    height: 40%;
  }
  48% {
    height: 70%;
  }
  52% {
    height: 30%;
  }
  56% {
    height: 10%;
  }
  60% {
    height: 30%;
  }
  64% {
    height: 50%;
  }
  68% {
    height: 60%;
  }
  72% {
    height: 70%;
  }
  76% {
    height: 80%;
  }
  80% {
    height: 70%;
  }
  84% {
    height: 60%;
  }
  88% {
    height: 50%;
  }
  92% {
    height: 60%;
  }
  96% {
    height: 70%;
  }
  100% {
    height: 80%;
  }
}
  /* top: 10%; */

/* .musicPlayer {
  height: 40px;
  width: auto;
  display: flex;
  align-items:center;
  justify-content: center;
}


#bars {
  height: 25px;
  margin: 0 0 0 0;
  position: absolute;
  display: flex;
  align-items:center;
  justify-content: center;
  width: 20px;
}

.bar {
 background: #1AFFAE;
  bottom: 1px;
  height: 3px;
  position: absolute;
  width: 3px;      
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
     opacity: .35;
      height: 3px; 
  }
  100% {
      opacity: 1;       
      height: 25px;        
  }
}

.bar:nth-child(1)  { left: 1px; animation-duration: 474ms; }
.bar:nth-child(2)  { left: 5px; animation-duration: 433ms; }
.bar:nth-child(3)  { left: 9px; animation-duration: 407ms; }
.bar:nth-child(4)  { left: 13px; animation-duration: 458ms; }
.bar:nth-child(5)  { left: 17px; animation-duration: 400ms; }
.bar:nth-child(6)  { left: 21px; animation-duration: 427ms; }
.bar:nth-child(7)  { left: 25px; animation-duration: 441ms; } */
/* .bar:nth-child(8)  { left: 29px; animation-duration: 419ms; }
.bar:nth-child(9)  { left: 33px; animation-duration: 487ms; }
.bar:nth-child(10) { left: 37px; animation-duration: 442ms; } */



.containerEnter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}


.btn {
  text-decoration: none;
  border: 1px solid rgb(9, 222, 143);
  position: relative;
  overflow: hidden;
  padding: 10px 20px;
  color: #fff;
}

.btn:hover {
  box-shadow: 1px 1px 25px 10px rgba(9, 222, 143, 0.4);
}

.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(9, 222, 143, 0.4),
    transparent
  );
  transition: all 650ms;
}

.btn:hover:before {
  left: 100%;
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #1AFFAE transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  pointer-events: none;
  justify-content: start !important;
  font-family: 'Kanit', sans-serif;
  line-height: 0px;
  text-transform: uppercase;
}

.number {
  font-size: 300px;
  color: #545864;
}

.number span {
  display: inline-block;
  position: relative;
}

.slopeBegin {
  /* background-color: black; */
  /* clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%); */
}

.slopeEnd {
  position: relative;
  width: 100%;
height: 100%;
  /* clip-path: polygon(70% 0, 100% 0, 80% 100%, 50% 100%); */
}

.slopeBegin {
  position: absolute;
  width: 170%;
  height: 100%;
  cursor: pointer;
}

.slopeEnd {
  /* position: absolute;
  width: 15%;
  right: 20%;
  bottom: 5%;
  height: 40%;
  cursor: pointer; */
}


.pink {
  background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
  background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
  background: linear-gradient(to right, tomato 0%, gold 100%);
}

.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.8); */
  width: 23rem;
  height: fit-content;

  border-radius: 10%;
}

.card img {
  /* margin-top: -20%; */
  width: 100%;
  border-radius: 20px;
}

.teamcard {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* background-color: rgba(0, 0, 0, 0.8); */
  width: 23rem;
  height: fit-content;

  border-radius: 10%;
}

.teamcard img {
  /* margin-top: -20%; */
  width: 80%;
  border-radius: 20px;
}

.logoSpinner {
  animation: coin-rotate 3s both infinite;
}

.smallcard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 6rem;
  height: fit-content;
  border-radius: 10%;
  animation: coin-rotate 3s both infinite;
}

.smallcard img {
  width: 100%;
  border-radius: 20px;
}

.card h2 {
  margin: 0;
  margin-top: 1rem;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.card .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rainbow_text_animated {
  background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}


*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.slider__navi {
  width:250px;
	position: absolute;
	top: 50%;
	right: 5%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	z-index: 999;
}

.slider__navi a {
	/* display: block; */
	/* height: 30px;
	width: 100%;
  padding: 20; */
	/* margin: 20px 10px; */
  /* text-align: center; */
	/* text-indent: -9999px;
	box-shadow: none;
	border: none; */
	/* background: rgba(0,0,0,0.2); */
}

.slider__navi a.active {
	/* background: rgba(255,255,255,1); */
}


.flex__container {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	-webkit-flex-flow: row wrap;
	-moz-flex-flow: row wrap;
	-ms-flex-flow: row wrap;
	-o-flex-flow: row wrap;
	flex-flow: row wrap; 
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	-o-justify-content: flex-start;
	justify-content: flex-start;
	height: 100vh;
	width: 100%;
	z-index: 1;
}

.flex__container.flex--active {
	z-index: 2;
}

.text--sub {
	font-size:1.3rem;
	letter-spacing: 0.5rem;
	text-transform: uppercase;
	margin-bottom: 40px;
}

.text--big {
	font-family: 'Poppins', sans-serif;
	font-size: 7.5em;
	font-weight: 700;
	line-height: 110px;
  margin-left: -8px;
}

.text--normal {
	font-size:1.3rem;
	color: rgba(255, 255, 255, 0.8);
	line-height: 22px;
	margin-top: 25px;
}

.text__background {
	font-family: 'Poppins', sans-serif;
	position: absolute;
	left: 72px;
	bottom: -60px;
	color: rgba(0,0,0,0.05);
	font-size: 170px;
	font-weight: 700;
}

.flex__item {
	height: 100vh;
	color: #fff;
	transition: transform 0.1s linear;
}

.flex__item--left {
	display: flex;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	align-items: center;
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	width: 65%;
	transform-origin: left bottom;
	transition: transform 0.1s linear 0.4s;
	opacity: 0;
	position: relative;
	overflow: hidden;
}

.flex__item--right {
	width: 35%;
	transform-origin: right center;
	transition: transform 0.1s linear 0s;
	opacity: 0;
}

.flex--preStart .flex__item--left,
.flex--preStart .flex__item--right,
.flex--active .flex__item--left,
.flex--active .flex__item--right {
	opacity: 1;
}

/* class0 */

.flex--class0 .flex__item--left {
	background: #FF3131;
}

.flex--class0 .flex__item--right {
	background: #630000;
}

/* class1 */

.flex--class1 .flex__item--left {
	background: #FFF01F;
  color: #000000;
}

.flex--class1 .flex__item--right {
	background: #867D00;
}

/* class2 */

.flex--class2 .flex__item--left {
	background: #BC13FE;
}

.flex--class2 .flex__item--right {
	background: #540175;
}

/* class3 */

.flex--class3 .flex__item--left {
	background: #476089;
}

.flex--class3 .flex__item--right {
	background: #112A50;
}

/* class4 */

.flex--class4 .flex__item--left {
	background: #424242;
}

.flex--class4 .flex__item--right {
	background: #000000;
}

.flex__content {
	margin-left: 80px;
	width: 55%;
	opacity: 1;
	transform: translate3d(0,0,0);
	transition: transform 0.2s linear 0.2s, opacity 0.1s linear 0.2s;
}

.class__img {
	position: absolute;
	bottom: 0;
	right: 15%;
	max-height: 50vw;
	opacity: 1;
	transform: translate3d(0,0,0);
	transition: opacity 0.43s 0.6s, transform 0.4s 0.65s cubic-bezier(0, 0.88, 0.4, 0.93);
}

/* Animate-START point */

.flex__container.animate--start .flex__content {
	transform: translate3d(0,-200%,0);
	opacity: 0;
}

.flex__container.animate--start .class__img {
	transform: translate3d(-200px,0,0);
	opacity: 0;
}

/* Animate-END point */

.flex__container.animate--end .flex__item--left {
	transform: scaleY(0);
}

.flex__container.animate--end .flex__item--right {
	transform: scaleX(0);
}

.flex__container.animate--end .flex__content {
	transform: translate3d(0,200%,0);
	opacity: 0;
}

.flex__container.animate--end .class__img {
	transform: translate3d(200px,0,0);
	opacity: 0;
}

.idcenter{
  position: absolute;
  top: 50%;
  right: -1%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}
.coin {
  
}
@keyframes coin-rotate {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

.pressStart{
  text-decoration: none;
  animation: press_start 1s infinite;

}

.pressStart:hover {
  animation: none;
  background-color: #000000;
}

@keyframes press_start {
  0%,100% {
      opacity: 1;
  }

  25% {
      opacity: 0.2;
  }
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

video {
  object-fit: contain;
  object-position: center;
}



@media screen and (max-width: 768px) and (orientation: portrait) {
  .card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.8); */
    width: 14rem;
    height: fit-content;
  
    border-radius: 10%;
  }
  
  .card img {
    /* margin-top: -20%; */
    width: 100%;
    border-radius: 20px;
  }

  .slider__navi {
    width:16%;
    position: absolute;
    top: 45%;
    right: 0;
    margin: 0 2%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 999;
  }
  

  .slopeBegin {
    position: absolute;
    width: 170%;
    height: 100%;
    cursor: pointer;
  }
  
  .slopeEnd {
    position: relative;
    width:100%;
    height: 100%;
  }

  .scrolldown{
    /* display: none; */
  }


.text--sub {
	font-size:1.1rem;
	letter-spacing: 0.5rem;
	text-transform: uppercase;
	margin-bottom: 30px;
}

.flex__content{
  margin-left: 20px;
  width: 90%;
}
.flex__item--left{ 
  display: block;
  padding-top: 40%;
  width:80%}

.flex__item--right{ width:20%}

.text--big {
	font-family: 'Poppins', sans-serif;
	font-size: 3.5em;
	font-weight: 700;
	line-height: 50px;
  margin-left: -8px;
}

.text--normal {
	font-size:1.3rem;
	color: rgba(255, 255, 255, 0.8);
	line-height: 22px;
	margin-top: 25px;
}


  .class__img {
    right: -5%;
    max-height: 60vh;

  }

  .teamcard {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    /* background-color: rgba(0, 0, 0, 0.8); */
    width: 23rem;
    height: fit-content;
  
    border-radius: 10%;
  }
  
  .teamcard img {
    /* margin-top: -20%; */
    width: 100%;
    border-radius: 20px;
  }
  

  .smallcard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 4rem;
    height: fit-content;
    border-radius: 10%;
  }
  
  .smallcard img {
    width: 100%;
    border-radius: 20px;
  }

  
}